.card_container {
    /* background-color: #F7F7F7; */
    height: 100%;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    /* overflow-y: auto; */
    /* margin-left: 4%; */
}

/* .card_class:hover {
    background: #f6f6f6 0% 0% no-repeat padding-box !important;
    -webkit-transition-duration: 300ms !important;
    -moz-transition-duration: 300ms !important;
    -o-transition-duration: 300ms !important;
    transition-duration: 300ms !important;
}

.card_class:hover .card_description {
    -webkit-transition-duration: 300ms;
    -moz-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms !important;
} */

.card_container .card_class {
    /* margin: 1em; */
    border: 0.01rem solid #E4E8EC;
    /* float: left; */
    /* border: 0.1rem solid #f9f9f9; */
    border-radius: 0%;
    position: relative;
    min-width: 5em;
    /* min-width: 8vw; */
    /* width: 7.5em; */
    flex: 0 0 11.11%;
    height: 8em;
    /* background: #F7F7F7 0% 0% no-repeat padding-box; */
    opacity: 1;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 0.7em;
}


.card_class .card_header {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em 0% 0% 8%;
}

.image_class {
    width: 2.8em;
    height: 2.8em;
}

.image_list_class {
    width: 3.5em;
    height: 3.5em;
}

.card_class .card_description {
    display: flex;
    /* flex-direction: column; */
    gap: 0.2em;
    /* margin-left: 8%; */
    text-align: center;
    padding: 3%;
}

.card_description .card__title {
    width: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.4;
    /* text-align: left; */
    /* color: #000; */
    color: #064674;
    opacity: 1;
    -webkit-font-kerning: normal;
    font-kerning: normal;
    /* padding-top: 3vh; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font: bold 12px aller;
}

.card_description .card__sub__title {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    letter-spacing: -0.22px;
    /* color: #787878; */
    color: #001954;
    opacity: 1;
    line-height: 1.4;
    font: normal 0.85em SourceSansProRegular;
}

.fav_icon {
    position: absolute;
    right: 0;
    width: 1.5em;
    top: 1%;
    cursor: pointer;
    /* margin-right: 5%; */
    margin: 2%;
}

.card_container .card_sub_container {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 75vh;
    align-content: flex-start;
    padding: 3px 7px;
    -webkit-overflow-scrolling: touch;
}

.card_container .div_title {
    padding-left: 1%;
    color: #535050;
    font: normal bold 1em aller;
}

.search_container {
    width: 100%;
    /* width: 80%; */
    /* border: 1px solid #CACACA; */
    display: flex;
    align-items: center;
    padding: 0.55em;
    gap: 1%;
    /* background: #f1f1f1; */
    margin: 0.3% 2%;
    border-radius: 5px;
}

.search_main_container {
    position: absolute;
    top: 0.6em;
    right: 0%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    width: 100%;
    align-items: center;
}

.search_main_headerPresent_container {
   position: unset !important;
}

.clear_icon_class {
    width: 1.5em;
    cursor: pointer;
}

.view_container {
    /* margin: 0.5%; */
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

.card_view_class {
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
    margin-right: 3vw;
}

.card_list_img {
    width: 1.5em;
}

.suffix_class img {
    width: 1em;
    margin-left: 0.3em;
}

.prefix_class img {
    width: 1.2em;
    cursor: pointer;
}

.search_container input {
    border: none;
    /* background-color: #f1f1f1; */
    /* font: normal 1em SourceSansProSemiBold; */
    font-size: 16px;
    font-family: SourceSansProSemiBold;
    width: 100%;
    /* color: #666; */
    border-radius: 3px;
}

.card_header_container {
    /* border-bottom: 0.1em solid #f6f6f6; */
    display: flex;
    align-items: center;
}

.search_container input[type="text"],
input[type="password"] {
    outline: none;
}

/* .tab_class & .MuiTab-root{
    color: red;
} */
.no_apps_class {
    display: flex;
    justify-content: center;
    font: normal 1.1em SourceSansProRegular;
    color: #666;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 70vh;
}

.no_apps_found {
    display: flex;
    justify-content: center;
    font: normal 1.1em SourceSansProRegular;
    color: #666;
    align-items: center;
    text-align: center;
    width: 100%;
    /* margin-top: 10%; */
    /* justify-content: center; */
    /* height: 100%; */
    height: 70vh;
}

.all_apps_navigation {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.all_apps_navigation .drag_img_class {
    cursor: pointer;
    width: 2.5em;
    height: 2.5em;
}

.card_container .card_sub_list_container {
    display: flex;
    /* overflow-y: auto; */
    overflow-y: scroll;
    height: 75vh;
    flex-direction: column;
    /* gap: 10px; */
    padding: 3px 15px;
    -webkit-overflow-scrolling: touch;
}

.card_container .card_sub_list_container_fromBrowser {
    height: 68vh !important;
}

.card_sub_list_container .card_list_class {
    /* border: 0.1rem solid #CACACA; */
    /* border-bottom: 0.01rem solid #CACACA; */
    border-bottom-width: 0.01rem;
    border-bottom-style: solid;
    /* border-radius: 3px; */
    /* background: #ffffff 0% 0% no-repeat padding-box; */
    opacity: 1;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    /* flex-direction: column; */
    gap: 8px;
    padding: 4px;
    min-height: 5.5em;
    /* min-height: 6.5em; */
    align-items: center;
}

.card__list_header {
    position: relative;
    padding: 3px;
}

/* .card_list_class:hover {
    background: #f6f6f6 0% 0% no-repeat padding-box !important;
    -webkit-transition-duration: 300ms !important;
    -moz-transition-duration: 300ms !important;
    -o-transition-duration: 300ms !important;
    transition-duration: 300ms !important;
}

.card_list_class:hover .card_list_description {
    -webkit-transition-duration: 300ms;
    -moz-transition-duration: 300ms;
    -o-transition-duration: 300ms;
    transition-duration: 300ms !important;
} */
.fav_list_icon {
    position: absolute;
    right: 0;
    width: 2.2em;
    cursor: pointer;
    margin: 0 0.5%;
}

.card_list_description {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 3px;
    max-width: 64em;
}

.card_list_description .card__title {
    font: normal 1em SourceSansProSemiBold;
    /* color: #064674; */
    opacity: 1;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card_list_description .card__sub__title {
    font: normal 0.9em SourceSansProSemiBold;
    /* color: #666; */
    opacity: 0.8;
    letter-spacing: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search_class {
    position: absolute;
    top: 3%;
    right: 2%;
}

.search_class .search_icon_class {
    cursor: pointer;
    width: 1.5em;
}

.loader_class_gif {
    display: flex;
    overflow-y: auto;
    align-items: center;
    justify-content: center;
}

.loader_class_browser_gif {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90%;
    width: 100%;
}

.loader_gif_image_web {
    height: 70%;
    margin-bottom: 6%;
}

.loader_gif_image {
    width: 30%;
    height: 100%;
}

.fab_icon {
    width: 1.5em;
    height: 1.5em;
}

.dragged_list_class {
    /* border: none !important;
    z-index: 100 !important; */
}

.top_class{
    position: absolute;
    display: flex;
    right: 0;
    align-items: center;
    gap: 1em;
}
.stasClass {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    display: flex;
    background: #FF3C3C;
    width: 27px;
    color: #fff;
    height: 27px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font: bold 13px/2vh SourceSansProRegular;
    /* font-size: 13px; */
    /* font-weight: bold; */
    /* font-family: 'SourceSansProRegular'; */
}
.footer_home_icon {
    bottom: 4%;
    display: flex;
    /* justify-items: center; */
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 47%;
}


@media only screen and (max-width : 600px) {
    .card_container {
        margin-left: 2% 2% 0% 2% !important;
        height: 100%;
    }

    .card_container .card_class {
        /* margin: 0.5em !important; */
        /* width: 8em !important; */
        flex: 0 0 33.333333% !important;
        height: 9em !important;
        gap: 0.4em;
    }

    .search_container {
        margin: 1.5% 2% !important;
    }

    .loader_class_gif {
        display: flex;
        overflow-y: auto;
        align-items: unset !important;
        justify-content: unset !important;
    }
    .loader_class_browser_gif {
        height: 100% !important;
    }
    .loader_gif_image_web {
        height: unset !important;
        width: 100% !important;
        margin-bottom: 12vh !important;
    }

    .loader_gif_image {
        width: 100% !important;
        height: 100% !important;
    }

    .card_list_description {
        max-width: 70% !important;
    }

    .card_container .card_sub_list_container {
        height: 77vh !important;
    }
    .card_container .card_sub_list_container_fromBrowser {
        height: 67vh !important;
    }
    /* .search_main_container {
        width: 100% !important;
    } */
}

@media only screen and (min-width: 601px) and (max-width: 915px) {
    .card_container .card_class {
        flex: 0 0 16.6666% !important;
    }
    .card_container .card_sub_list_container_fromBrowser {
        height: 78vh !important;
    }
}
@media all and (display-mode: standalone) {
    .card_container .card_sub_list_container_fromBrowser {
        height: 76vh !important;
    }
}