@import url('../../Shared/Utilities/commonStyles.css');

.header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5em;
    /* background: #F7F7F7; */
    /* padding-bottom: 0.5%; */
}

.header_left_header {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.header_container .header_left_header .logo_class {
    cursor: pointer;
    width: 4em;
    height: 4em;
    /* width: 6em; */
    /* margin-top: 3px; */
}

.header_left_header .header__title {
    font: bold 1.5em SourceSansProSemiBold;
}

.header_container .right_header {
    width: 50%;
    display: flex;
    flex-direction: row;
    gap: 3%;
    justify-content: end;
    align-items: center;
    margin-right: 1%;
    align-items: flex-start;
}

.right_header .profile_class {
    display: flex;
    flex-direction: column;
    margin-top: 0.7em;
}

.profile_class .profile__name {
    font: bold 0.8em aller;
}

.profile_class .profile_designation {
    font: normal 0.8em aller;
}

.right_header .profile_image .profile_icon {
    width: 2.8em;
    height: 2.8em;
    border-radius: 50%;
    background: rgb(123, 203, 226);
    color: #fff;
    text-align: center;
    font: bold aller;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    cursor: pointer;
}

.profile_image .img_circle {
    width: 2.8em;
    height: 2.8em;
    border-radius: 50%;
}

.header_height_change {
    height: 4em !important;
}

.header_height_change_OnAppOpen {
    height: 0.3em !important;
    /* height: 0.0em !important; */
    /* display: none !important; */

}

@media only screen and (max-width: 600px) { 

    .profile_class .profile__name {
        max-width: 18em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .header_container .right_header {
        margin-right: 2% !important;
    }
}