/* .iframe_Class {
    background-color: unset !important;
} */
.swipedData_class {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.swiped_info_container {
    height: 100%;
}

.swiped_info_container .no_data {
    align-items: center;
    color: #666;
    display: flex;
    font: 600 1.1em SourceSansProRegular;
    height: 87.5vh;
    justify-content: center;
    width: 100%;
    overflow-y: hidden;
    letter-spacing: -0.px;
}

.swiped_info_container .swiped_info {
    display: flex;
    flex-direction: column;
    gap: 7px;
    /* padding: 3% 3% 3% 6%; */
    align-items: center;
    padding: 3% 0% 0% 0%;
    /* justify-content: center; */
    height: 80vh;
}

.green_Class {
    color: #1ECB40 !important;
}

.blue_color_class {
    color: #0090FF !important;
}

.today_date_title {
    font: normal 2em SourceSansProRegular;
    letter-spacing: -1.4px;
    /* color: #333; */
}

.today_date_title2 {
    font: normal 1.1em SourceSansProRegular;
    letter-spacing: -0.22px;
    margin-bottom: 0.5em;
    /* color: #333; */
}

.timer_class {
    font: 700 3.9em SourceSansProRegular;
    letter-spacing: -0.2px;
    margin-top: 10px;
    /* margin-bottom: 10px; */
    color: #0090FF;
}

.bottom_text {
    font: 700 0.6em SourceSansProRegular;
    letter-spacing: -1.3px;
}

.timer_value {
    font: normal 1.1em SourceSansProRegular;
    letter-spacing: -0.22px;
    /* color: #666; */
    opacity: 0.6;
}

.progress-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1%;
}

.circular-chart {
    max-width: 100%;
    max-height: 100%;
}

.circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2.8;
}

.circle {
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1.4s ease-out forwards;
}

.skyBlue {
    stroke: #0090FF;
}

.green_stoke {
    stroke: #1ECB40;
}

.percentage {
    /* font-size: 0.5em; */
    text-anchor: middle;
    /* fill: #333; */
    font: normal 0.5em SourceSansProRegular;
    letter-spacing: -0.22px;
}

.hrs_min_class {
    font: normal 0.2em SourceSansProRegular;
    opacity: 0.6;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}

@media only screen and (max-width : 600px) {
    .swiped_info_container .swiped_info {
        justify-content: center;
        padding: 0% 0% 0% 0% !important;
        height: 80vh !important;
        overflow-y: hidden;
    }

    .progress-circle-container {
        padding: 3% !important;
    }

    .bottom_text {
        margin-left: -0.2em !important;
    }

    /* .timer_value {
        margin-top: -1% !important;
    } */

}

@media only screen and (min-width: 601px) and (max-width: 915px) {
    .swiped_info_container .swiped_info {
        justify-content: center;
        height: 94vh !important;
        padding: 0% 0% 0% 0% !important;
    }

    .progress-circle-container {
        padding: 4% !important;
    }

    .bottom_text {
        margin-left: -0.2em !important;
    }
}