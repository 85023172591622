.reportClass {
    height: 100%;
}

.reportClass {
    /* display: flex;
    width: 90vw;
    height: 35vw; */
    /* border: none !important; */
    iframe {
        border: none !important;
    }
}

@media only screen and (max-width: 600px) {
    .reportClass {
        height: 100% !important;
    }
}