.iframe_Class {
    /* display: none; */
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #fff;
}

.iframe_Class .powerBiScreen {
    height: 90%;
    width: 100%;
    border: none;
}

.iframe_Class .seatAllocation_powerBiScreen {
    height: 86% !important;
}

/* .iframe_Class  */
.back_arrow {
    width: 2.3em !important;
    height: 2.3em !important;
    cursor: pointer;
}

.iframe_header {
    display: flex;
    justify-content: space-between;
    padding: 0.5% 0% 1% 0.5%;
    /* box-shadow: 10px 10px 8px #c4c4c4; */
}

.left_header {
    align-items: center;
    display: flex;
    gap: 3%;
    margin-left: .8%;
    width: 80%;
}

.iframe_header .right_header {
    margin-right: 2%;
}

.iframe_header .dashboard_title {
    font: normal 1.2em SourceSansProSemiBold;
    opacity: 0.9;
    letter-spacing: 0;
}
.title_class {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title_class .dashboard_img {
    height: 3.5em;
    width: 3em;
    display: flex;
    align-items: center;
}
.dashboard_img .image_class{
    width: 2.3em;
    height: 2.3em;
}

.timesheet_powerBiScreen { 
    /* margin-left: 7% !important;   */
}

#responsiveScalingCanvasHost {
    margin-left: 8% !important;
}

@media only screen and (max-width: 600px) {
    .iframe_header {
        /* padding: 3% 0% 3% 0.5% !important; */
        padding: 1% 0% 3% 0.5% !important;
    }
    .left_header {
        gap: 5% !important;
    }
    .back_arrow {
        width: 1.3em;
        height: 1.3em;
    }
    .timesheet_powerBiScreen { 
        margin-left: 7% !important;  
    }
}
@media only screen and (min-width: 601px) and (max-width: 915px) {
    .timesheet_powerBiScreen { 
        margin-left: 15% !important;  
    }
}
@media all and (display-mode: standalone) {
    .timesheet_powerBiScreen { 
        margin-left: 2% !important;  
    }
}