@import 'toastr';

* {
  box-sizing: border-box;
  /* overscroll-behavior-y: contain; */
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-text-size-adjust: none; */
}

/* @font-face {
  font-family: 'aller';
  src: local('aller'),
        url('./../public/Aller_Typo_Rg.ttf') format('truetype'),
} */

@font-face {
  font-family: aller;
  src: url('./../public/Aller_Typo_Rg.ttf');
}

@font-face {
  font-family: SourceSansProRegular;
  src: url('./../public/SourceSansPro-Regular_0.ttf');
}

@font-face {
  font-family: SourceSansProSemiBold;
  src: url('./../public/SourceSansPro-Semibold_0.ttf');
}

.ptr__pull-down--pull-more{
  display: none !important;
}

.errorMsg_class{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90%;
  font-size: 1.5em;
  /* font-weight: bold; */
  color: #666;
}
.loader_class{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
}
.loader_class .loader_img{
  width: 100%;
  height: 100%;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  /* background: #ddd; */
  -webkit-box-shadow: inset 0 0 6px #ccc;
}

*::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border-radius: 4px;
}
#toast-container>.toast-success {
  background-color: #75d00f;
  color: #ffffff;
  box-shadow: 0px 6px 16px #00000029;
  font: normal 0.9em SourceSansProRegular !important;
  opacity: 1;
  max-width: 30em;
}
#toast-container>.toast-error {
  background-color: #f23950;
  color: #ffffff;
  box-shadow: 0px 6px 16px #00000029;
  font: normal 0.9em SourceSansProRegular !important;
  opacity: 1;
  max-width: 30em;
}
#toast-container>.toast-info {
  background-color: #ffb709;
  color: #ffffff;
  box-shadow: 0px 6px 16px #00000029;
  font: normal 0.9em SourceSansProRegular !important;
  opacity: 1;
  max-width: 30em;
}

#responsiveScalingCanvasHost {
  margin-left: 7% !important;
}

@media only screen and (max-width: 768px) {
  body {
    /* width: fit-content; */
  }
  *::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }
}

@media only screen and (max-width : 600px) {
  *::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }
}